import { useState, useRef } from 'react'
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import firebase from 'firebase/app';
import { useAuth } from '../contexts/AuthContext';
import { customersRef } from '../firebase';
import { Link, useHistory } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function AddCustomer() {

	const nameRef = useRef( "" );
	const addressRef = useRef( "" );
	const cityRef = useRef( "" );
	const postalCodeRef = useRef( "" );
	const provinceRef = useRef( "BC" );
	const phoneNumberRef = useRef( "" );
	const typeRef = useRef( "LRS" );			// LRS | Licensee | Agency | Counter | VQA | RAS | MOS
	const licenseNumberRef = useRef( 0 );

    const { currentUser } = useAuth();
	
	const [ error, setError ] = useState('');
    const history = useHistory();

	async function handleSubmit( e ) {
		e.preventDefault();

	   // do we have a customer with the same name or license number?

	   const newCustomer = {
			name: nameRef?.current?.value,
			address: addressRef?.current?.value,
			city: cityRef?.current?.value,
			postal_code: postalCodeRef?.current?.value,
			province: provinceRef?.current?.value,
			phone_num: phoneNumberRef?.current?.value,
			type: typeRef?.current?.value,
			license_number: licenseNumberRef?.current?.value,
			created_by: currentUser.uid,
			created: firebase.firestore.FieldValue.serverTimestamp(),
			modified_by: currentUser.uid,
			modified: firebase.firestore.FieldValue.serverTimestamp()
		};

		try {
			await customersRef.add( newCustomer );
			history.push( "/customers" );
		} catch ( err ) {
			setError( error );
		}
	}

	return (
		<>
			<Card>
				<Card.Body>
				<h2>Add a new Customer</h2>
				<br></br>
                { error && <Alert variant="danger">{error}</Alert> }

				<Form onSubmit={handleSubmit}>

					<Form.Group as={Row}>
						<Form.Label column sm={3}>Name</Form.Label>
						<Col sm={9}>
							<Form.Control type="text" ref={nameRef} required placeholder="" />
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column sm={3}>Address</Form.Label>
						<Col sm={9}>
							<Form.Control type="text" ref={addressRef} required placeholder="" />
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column sm={3}>City</Form.Label>
						<Col sm={3}>
							<Form.Control type="text" ref={cityRef} required placeholder="" />
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column sm={3}>Province</Form.Label>
						<Col sm={2}>
							<Form.Control type="text" ref={provinceRef} required placeholder="" />
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column sm={3}>Postal Code</Form.Label>
						<Col sm={2}>
							<Form.Control type="text" ref={postalCodeRef} required placeholder="" />
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column sm={3}>Phone</Form.Label>
						<Col sm={3}>
                            <Form.Control type="tel" ref={phoneNumberRef} required placeholder="(604)111-1111" />
						</Col>
                        
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column sm={3}>Type</Form.Label>
						<Col sm={9}>
							<Form.Control className="w-50" as="select" ref={typeRef}>
							<option>LRS</option>
							<option>RAS</option>
							<option>Licensee</option>
							<option>Agency</option>
							<option>Counter</option>
							<option>VQA</option>
							<option>MOS</option>
							<option>Hospitality</option>
							</Form.Control>
						</Col>
					</Form.Group>

					<Form.Group as={Row}>
						<Form.Label column sm={3}>BCLDB Number</Form.Label>
						<Col sm={2}>
							<Form.Control type="number" ref={licenseNumberRef} required placeholder="" />
						</Col>
					</Form.Group>

					<Button disabled={false} className="w-50" type="submit">Submit</Button>


					<div><Link to={ '/customers' }>Cancel</Link></div>
					
				</Form>
				</Card.Body>
			</Card>
		</>
	);
}