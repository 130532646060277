import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Navbar from "react-bootstrap/NavBar";
import Col from "react-bootstrap/Col";
import { useAuth } from "../contexts/AuthContext";
import { useUserData } from "../contexts/UserDataContext";
import { producersRef } from "../firebase";

export default function SubHeader() {
	const { currentUser } = useAuth();

	const { currentUserData, updateUserData } = useUserData();

	const [isLoading, setIsLoading] = useState(true);
	const [producerData, setProducerData] = useState(null);

	const activeProducerRef = useRef("");

	useEffect(() => {
		if (!currentUser) return;

		let unsubscribe = producersRef.onSnapshot((snapshot) => {
			setProducerData(snapshot);
			setIsLoading(false);
		});

		return unsubscribe;
	}, [currentUser]);

	async function onProducerChanged(e) {
		e.preventDefault();

		try {
			updateUserData({ active_producer: e.target.value });
		} catch (err) {
			console.log(err);
		}
	}

	if (!currentUser) {
		return <></>;
	}

	return (
		<>
			<Navbar bg="dark" variant="dark" expand="lg" sticky="top">
				<Col sm={3}>
					<Form.Control className="w-100" as="select" value={currentUserData?.active_producer} ref={activeProducerRef} onChange={onProducerChanged}>
						{!isLoading &&
							producerData &&
							producerData.docs.map((doc) => (
								<option key={doc.id} value={doc.id}>
									{doc.data().name}
								</option>
							))}
					</Form.Control>
				</Col>
			</Navbar>
		</>
	);
}
