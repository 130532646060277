import React, { useRef, useState } from 'react'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function Signup() {

    const emailRef = useRef<HTMLInputElement>( null );
    const passwordRef = useRef<HTMLInputElement>( null );
    const passwordConfirmRef = useRef<HTMLInputElement>( null );

    const [ error, setError ] = useState<string>('');
    const [ loading, setLoading ] = useState<boolean>( false );

    const { signup } = useAuth();
    const history = useHistory();

    async function handleSubmit( e: React.FormEvent<HTMLFormElement> ) {
        e.preventDefault();

        if ( passwordRef?.current?.value !== passwordConfirmRef?.current?.value ) {
            return setError( 'Passwords do not match' );
        }

        try {
            setError( '' );
            setLoading( true );
            await signup( emailRef?.current?.value, passwordRef?.current?.value );
            history.push( "/" );
        } catch ( error ) {
            setError( 'Failed to create an account - ' + error );
        } finally {            
            console.log( "success?" );
            setLoading( false );
        }
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Sign Up</h2>
                    { error && <Alert variant="danger">{error}</Alert> }
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Form.Group id="password-confirm">
                            <Form.Label>Password Confirmation</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100" type="submit">Sign Up</Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Already have an account? <Link to="/login">Log In</Link>
            </div>
        </>
    )
}
