export const OrderType = {
	DOC60: 0,
	DOC55: 1,

	NUM_TYPES: 2,

	properties: {
		0: { display: "Doc #60" },
		1: { display: "Doc #55" },
	},
};
