import { useState, useEffect } from "react";
import { CurrencyFormat } from "../CurrencyFormat";

import { Text, View } from "@react-pdf/renderer";

export default function PrintOrderProducts(props) {
	const [totals, setTotals] = useState({});

	useEffect(() => {
		if (props.orderData === null) return;

		let unsubscribe = props.orderData.ref.collection("products").onSnapshot((snapshot) => {
			if (snapshot) {
				let subtotal_accum = 0;
				let deposit_accum = 0;

				// for each product
				snapshot.docs.map((doc) => {
					subtotal_accum += doc.data().unit_count * doc.data().case_size * doc.data().cost;
					deposit_accum += doc.data().unit_count * doc.data().case_size * doc.data().deposit;
					return 0;
				});

				setTotals({
					gst: subtotal_accum * 0.05,
					subTotal: subtotal_accum,
					deposit: deposit_accum,
				});
			}
		});

		return () => {
			unsubscribe();
		};
	}, [props.orderData]);

	return (
		<View style={props.pageStyles.TotalsBox}>
			<View style={props.pageStyles.TotalsBoxRow}>
				<Text style={props.pageStyles.TotalsBoxLabel}>Subtotal:</Text>
				<Text style={props.pageStyles.TotalsBoxValue}>{CurrencyFormat.format(totals.subTotal / 100)}</Text>
			</View>

			<View style={props.pageStyles.TotalsBoxRow}>
				<Text style={props.pageStyles.TotalsBoxLabel}>GST @ 5%:</Text>
				<Text style={props.pageStyles.TotalsBoxValue}>{CurrencyFormat.format(totals.gst / 100)}</Text>
			</View>

			<View style={props.pageStyles.TotalsBoxRow}>
				<Text style={props.pageStyles.TotalsBoxLabel}>PST:</Text>
				<Text style={props.pageStyles.TotalsBoxValue}>{CurrencyFormat.format(0)}</Text>
			</View>

			<View style={props.pageStyles.TotalsBoxRow}>
				<Text style={props.pageStyles.TotalsBoxLabel}>Deposit:</Text>
				<Text style={props.pageStyles.TotalsBoxValue}>{CurrencyFormat.format(totals.deposit / 100)}</Text>
			</View>

			<View style={props.pageStyles.TotalsBoxRowFinal}>
				<Text style={props.pageStyles.TotalsBoxLabel}>Total Cost:</Text>
				<Text style={props.pageStyles.TotalsBoxValue}>{CurrencyFormat.format((totals.subTotal + totals.gst + totals.deposit) / 100)}</Text>
			</View>
		</View>
	);
}
