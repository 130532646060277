import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/NavBar';
import { useAuth } from '../contexts/AuthContext';
			  
export default function Header() {
	
    const { currentUser } = useAuth();
	
	if ( !currentUser )
	{
		return (
			<>
				<Navbar bg="dark" variant="dark" expand="lg" sticky="top">
					<Navbar.Brand href="/">Fieldstone</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
				</Navbar>
			</>
		)
	}

	let loginLabel = null;
	
	if ( currentUser )
	{
		loginLabel = 
			<Navbar.Text>
				Signed in as: <a href="/update-profile">{ currentUser.email}</a>
			</Navbar.Text>
	}

	return (
		<>
			<Navbar className="HeaderBar" bg="dark" variant="dark" expand="lg" sticky="top">
				<Navbar.Brand href="/">Fieldstone</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto">
					<Nav.Link href="/orders">My Orders</Nav.Link>
					<Nav.Link href="/customers">Customers</Nav.Link>
					<Nav.Link href="/producers">Producers</Nav.Link>
					</Nav>
					{ loginLabel }
				</Navbar.Collapse>
			</Navbar>
		</>
	)
}
