import { useState, useEffect, useCallback } from "react";
import { ListGroup } from "react-bootstrap";
import { CurrencyFormat } from "../CurrencyFormat";
import OrderProduct from "./OrderProduct";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function EditOrderProducts(props) {
	const [products, setProducts] = useState(null);

	const [totals, setTotals] = useState({});

	useEffect(() => {
		//console.log("useEffect");
		if (props.orderData === null) return;

		let unsubscribe = props.orderData.ref.collection("products").onSnapshot((snapshot) => {
			setProducts(snapshot);

			//console.log("snapshot");

			if (snapshot) {
				let subtotal_accum = 0;
				let deposit_accum = 0;

				// for each product
				snapshot.docs.map((doc) => {
					subtotal_accum += doc.data().unit_count * doc.data().case_size * doc.data().cost;
					deposit_accum += doc.data().unit_count * doc.data().case_size * doc.data().deposit;
					return 0;
				});

				setTotals({
					gst: subtotal_accum * 0.05,
					subTotal: subtotal_accum,
					deposit: deposit_accum,
				});
			}
		});

		return () => {
			unsubscribe();
		};
	}, [props.orderData]);

	let noProducts = <></>;
	if (products && products.size === 0) {
		noProducts = <p>No Products created for this Producer</p>;
	}

	// as props, pass
	// doc
	// fn to call when the count changed - submits the order change, which calls useEffect to update the cost

	//async function SetUnitCount(docId, unit_count) {

	const SetUnitCount = useCallback(
		async (docId, unitCount) => {
			//console.log("SetUnitCount", docId, unitCount);

			if (unitCount < 0) return;

			try {
				await props.orderData.ref
					.collection("products")
					.doc(docId)
					.update({ unit_count: Number(unitCount) });
			} catch (err) {
				console.log(err);
			}
		},
		[props.orderData.ref]
	);

	const SetCost = useCallback(
		async (docId, cost) => {
			if (cost < 0) return;

			try {
				//console.log("set cost to ", cost);
				await props.orderData.ref
					.collection("products")
					.doc(docId)
					.update({ cost: Number(cost) });
			} catch (err) {
				console.log(err);
			}
		},
		[props.orderData.ref]
	);

	function SortByNameAndCaseSize(a, b) {
		if (a.data().name !== b.data().name) return a.data().name < b.data().name ? 1 : -1;
		return a.data().case_size - b.data().case_size;
	}

	//console.log("parent render");
	return (
		<>
			{noProducts}

			<Row>
				<Col sm={5}>
					<h4>Name</h4>
				</Col>
				<Col sm={2}>
					<h4>Format</h4>
				</Col>
				<Col sm={2}>
					<h4>Price</h4>
				</Col>
				<Col sm={3}>
					<h4>Count</h4>
				</Col>
			</Row>

			<ListGroup>
				{products &&
					products.docs
						.sort(SortByNameAndCaseSize)
						.map((doc) => <OrderProduct disabled={props.disabled} key={doc.id} doc={doc} SetUnitCount={SetUnitCount} SetCost={SetCost} />)}
			</ListGroup>

			<br />

			<p>Subtotal: {CurrencyFormat.format(totals.subTotal / 100)}</p>
			<p>GST @ 5%: {CurrencyFormat.format(totals.gst / 100)}</p>
			<p>PST: {CurrencyFormat.format(0)}</p>
			<p>Deposit: {CurrencyFormat.format(totals.deposit / 100)}</p>
			<p>Total Cost: {CurrencyFormat.format((totals.subTotal + totals.gst + totals.deposit) / 100)}</p>
		</>
	);
}
