import { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import firebase from "firebase/app";
import { useAuth } from "../contexts/AuthContext";
import { productsRef } from "../firebase";
import { Link, useHistory, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function AddProduct() {
	const nameRef = useRef("");
	const skuRef = useRef("");
	const unitSizeRef = useRef("");

	const { producerId } = useParams();
	const { currentUser } = useAuth();

	const [error, setError] = useState("");

	const history = useHistory();

	const [cost, setCost] = useState(0);
	const [deposit, setDeposit] = useState(0);

	async function handleSubmit(e) {
		e.preventDefault();

		const newProduct = {
			name: nameRef?.current?.value,
			size_in_ml: unitSizeRef?.current?.value,
			sku_id: skuRef?.current?.value,

			// price in cents
			cost: Math.round(cost * 100),
			deposit: Math.round(deposit * 100),

			case_sizes: [1],

			producerId: producerId,
			created_by: currentUser.uid,
			created: firebase.firestore.FieldValue.serverTimestamp(),
			modified_by: currentUser.uid,
			modified: firebase.firestore.FieldValue.serverTimestamp(),
		};

		try {
			productsRef.add(newProduct);
		} catch (error) {
			console.log(error);
			setError("Error Adding new Product");
		} finally {
			history.push(`/producers/edit/${producerId}`);
		}
	}

	function onCostChange(values) {
		const { value } = values;
		setCost(value);
	}

	function onDepositChange(values) {
		const { value } = values;
		setDeposit(value);
	}

	return (
		<>
			<Card>
				<Card.Body>
					<h2>Add a new Product</h2>
					<br />

					<Form onSubmit={handleSubmit}>
						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Name
							</Form.Label>
							<Col sm={9}>
								<Form.Control type="text" ref={nameRef} required placeholder="" />
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								SKU ID
							</Form.Label>
							<Col sm={3}>
								<Form.Control type="text" ref={skuRef} required placeholder="" />
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Size in mL
							</Form.Label>
							<Col sm={3}>
								<Form.Control type="number" ref={unitSizeRef} required placeholder="" />
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Price
							</Form.Label>
							<Col sm={3}>
								<NumberFormat
									value={cost}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={"$"}
									allowNegative={false}
									onValueChange={onCostChange}
									type="tel"
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Deposit
							</Form.Label>
							<Col sm={3}>
								<NumberFormat
									value={deposit}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={"$"}
									allowNegative={false}
									onValueChange={onDepositChange}
									type="tel"
								/>
							</Col>
						</Form.Group>

						{error && <Alert variant="danger">{error}</Alert>}
						<Button disabled={false} className="w-50" type="submit">
							Submit
						</Button>
						<div>
							<Link to={"/producers"}>Cancel</Link>
						</div>
					</Form>
				</Card.Body>
			</Card>
		</>
	);
}
