import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import { useState, useEffect } from 'react'

const app = firebase.initializeApp( {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID 
});

// firestore
export const db = firebase.firestore();
export const storage = firebase.storage().ref();

interface QueryState {
    isLoading: boolean,
    data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> | null
};

export const FirestoreQuery = (ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>) => {
	const [docState, setDocState] = useState<QueryState>({
	  isLoading: true,
	  data: null
	});

    //console.log( "query", ref );
    //console.log(new Error().stack);

    useEffect( () => {
        const unsubscribe = ref.onSnapshot( (docs) => {
            setDocState( {
              isLoading: false,
              data: docs   
            });
          });
        return () => {
            unsubscribe();
        }
    }, [ref] );
	
	return docState;
}

export const FirestoreGet = (ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>) => {

    const [collection, setCollection] = useState<QueryState>( {
        isLoading: true,
        data: null
    });

    console.log( "GET" );

    useEffect( () => {
        let fetchData = async () => {
            try {
                console.log( "TRY" );
				const response: any = await ref.get().then( (doc) => {
                    console.log( doc );
                });
                console.log( "r = ", response );
                setCollection( { 
                    isLoading: false,
                    data: response
                });
			} catch (err) {
				console.error(err);
			}
        }

        fetchData();
    }, [ref] );

    return collection;
}

export const auth = app.auth();

export const userDataRef = db.collection( 'userdata' );
export const producersRef = db.collection( 'producers' );
export const ordersRef = db.collection( 'orders' );
export const customersRef = db.collection( 'customers' );
export const warehousesRef = db.collection( 'warehouses' );
export const productsRef = db.collection( 'products' );


export default app;