import { useState, useEffect, useRef } from "react";
import firebase from "firebase/app";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useAuth } from "../contexts/AuthContext";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { warehousesRef } from "../firebase";

export default function Warehouses(props) {
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [warehouses, setWarehouses] = useState(null);
	const [showAddModel, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [editWarehouse, setEditWarehouse] = useState(null);

	const [editLoading, setEditLoading] = useState(false);

	const { currentUser } = useAuth();

	useEffect(() => {
		setLoading(true);

		let query = warehousesRef.where("producerId", "==", props.producerId);
		const unsubscribe = query.onSnapshot((snapshot) => {
			setWarehouses(snapshot);
			setLoading(false);
		});

		return () => {
			unsubscribe();
		};
	}, [props.producerId]);

	const nameRef = useRef("");
	const editNameRef = useRef("");

	function addWarehouse() {
		const newWarehouse = {
			name: nameRef?.current?.value,
			producerId: props.producerId,
		};

		warehousesRef.add(newWarehouse);
	}

	const handleShowEditModal = (doc) => {
		setEditWarehouse(doc);
		setShowEditModal(true);
	};

	const handleModalCancel = () => {
		setShowAddModal(false);
		setShowEditModal(false);
	};

	const handleAddModalSubmit = () => {
		setShowAddModal(false);
		addWarehouse();
	};

	const handleEditModalSubmit = async () => {
		const updateWarehouse = {
			name: editNameRef?.current?.value,
			modified_by: currentUser.uid,
			modified: firebase.firestore.FieldValue.serverTimestamp(),
		};

		try {
			setEditLoading(true);
			await warehousesRef.doc(editWarehouse.id).update(updateWarehouse);
			setShowEditModal(false);
		} catch (err) {
			setError("Error updating warehouse");
			window.setTimeout(() => {
				setError("");
			}, 2000);
			console.log("Error = ", err);
		} finally {
			setEditLoading(false);
			setEditWarehouse(null);
		}
	};

	// this catches the error, sets a timer and clears it if we navigate away before it's done
	useEffect(() => {
		if (error) {
			const timer = setTimeout(() => setError(""), 2000);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [error]);

	return (
		<>
			{/* Add a warehouse Modal*/}
			<Modal show={showAddModel} onHide={handleModalCancel}>
				<Modal.Header closeButton>
					<Modal.Title>New Warehouse</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddModalSubmit}>
						<Form.Group>
							<Form.Label>Name</Form.Label>
							<Form.Control type="text" ref={nameRef} required />
						</Form.Group>
						<Button disabled={false} className="w-50" type="submit">
							Submit
						</Button>
					</Form>
				</Modal.Body>
			</Modal>

			{/* Edit a warehouse Modal*/}
			{editWarehouse && (
				<Modal show={showEditModal && editWarehouse !== null} onHide={handleModalCancel}>
					<Modal.Header closeButton>
						<Modal.Title>Edit Warehouse</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form onSubmit={handleEditModalSubmit}>
							<Form.Group>
								<Form.Label>Name</Form.Label>
								<Form.Control type="text" ref={editNameRef} required defaultValue={editWarehouse.data().name} />
							</Form.Group>
							<Button disabled={editLoading} className="w-50" type="submit">
								Update
							</Button>
						</Form>
					</Modal.Body>
				</Modal>
			)}

			<Card>
				<Card.Body>
					{error && <Alert variant="danger">{error}</Alert>}

					{loading && (
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					)}

					<ListGroup>
						{warehouses &&
							warehouses.docs.map((doc) => (
								<ListGroupItem key={doc.id}>
									{doc.data().name}
									<Button variant="link" onClick={() => handleShowEditModal(doc)}>
										edit
									</Button>
									<Button variant="link" onClick={(e) => warehousesRef.doc(doc.id).delete()}>
										delete no confirm
									</Button>
								</ListGroupItem>
							))}
					</ListGroup>

					<ListGroupItem>
						<Button variant="link" onClick={() => setShowAddModal(true)}>
							Add Warehouse
						</Button>
					</ListGroupItem>
				</Card.Body>
			</Card>
		</>
	);
}
