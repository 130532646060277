import { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import EditOrder from "./EditOrder";
import SubHeader from './SubHeader';
import { ListGroup, ListGroupItem } from "react-bootstrap";
import firebase from "firebase/app";
import { ordersRef, producersRef, productsRef /* , FirestoreQuery  */ } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { Switch, Route, useRouteMatch, Link } from "react-router-dom";
import { useUserData } from "../contexts/UserDataContext";
import { OrderStatus } from "../OrderStatus";
import { OrderType } from "../OrderType";

import {QuerySnapshot, Query, DocumentData, DocumentSnapshot} from '@firebase/firestore-types';

export default function Orders() {
	const [error /* , setError */] = useState<string>("");
	const { currentUser } = useAuth();
	const { currentUserData } = useUserData();
	const { path, url } = useRouteMatch();

	const [showAddModel, setShowAddModal] = useState<boolean>(false);
	const [addOrderProducer, setAddOrderProducer] = useState(null);

	const [highestInvoice, setHighestInvoice] = useState( -1 );

	const [activeProducerName, setActiveProducerName] = useState<string>("");

	const [orders, setOrders] = useState<QuerySnapshot<DocumentData> | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);

	// Get the active producer's name
	useEffect(() => {
		let fetch = async () => {
			if (currentUserData == null) return;

			try {

				const response: DocumentSnapshot<DocumentData> = await producersRef.doc(currentUserData?.active_producer).get();

				let data: DocumentData | undefined = response.data();
	
				if ( data === undefined )
					return;
	
				setActiveProducerName(data.name);
			} catch ( err )
			{
				console.log( err );
			}			
		};

		fetch();
	}, [currentUserData]);

	// Get orders for the active producer
	useEffect(() => {
		if (currentUserData == null) return;

		setLoading(true);

		if ( currentUserData.active_producer == null )
			return;

		let query: Query = ordersRef.where("producerId", "==", currentUserData.active_producer);
		const unsubscribe = query.onSnapshot((snapshot) => {
			setOrders(snapshot);

			let highest = -1;
			for ( const doc of snapshot.docs )
			{
				let val = parseInt( doc.data().invoiceNumber ) || 0;
				if ( val > highest )
					highest = val;
			}
			setHighestInvoice( highest );
			  
			setLoading(false);
		});

		return () => {
			unsubscribe();
		};
	}, [currentUserData]);

	const showAddModalForCurrentProducer = () => {
		if (currentUserData.active_producer === null) return;

		setAddOrderProducer(currentUserData.active_producer);
		setShowAddModal(true);
	};

	const handleModalCancel = () => {
		setShowAddModal(false);
	};

	const handleAddModalSubmit = () => {
		setShowAddModal(false);
		addOrder();
	};

	async function addOrder() {
		try {
			// get all the products for addOrderProducer
			let productsResponse = await productsRef.where("producerId", "==", addOrderProducer).get();

			if ( productsResponse && productsResponse.size === 0 )
			{
				alert( "This producer has no registered products. Create some product entries first." );
				return;
			}

			let orderId = await ordersRef.add({
				producerId: addOrderProducer,
				preparerId: currentUser.uid,
				customerId: "",
				warehouseId: "",
	
				invoiceNumber: highestInvoice + 1,
	
				formType: OrderType.DOC60,
	
				status: OrderStatus.CREATED, // "printed", "delivered", "posted", "paid"
	
				created_by: currentUser.uid,
				created: firebase.firestore.FieldValue.serverTimestamp(),
			});		

			//console.log( productsResponse );

			// add defaults for the order's product data ( all 0 count, init the price to the list price )
			// count is the bottle count

			// blow out the products into each case size

			productsResponse.docs.map( (product) => {

				if ( product.data().case_sizes === undefined || product.data().case_sizes.size === 0 )
					return 0;

				// strip the case_sizes field
				const { case_sizes, ...productWithoutCaseSizes } = product.data();

				product.data().case_sizes.map( (n: number) => (
					ordersRef.doc( orderId.id ).collection( "products" ).add( {
						...productWithoutCaseSizes,
						case_size: Number(n),
						unit_count: 0,
					} )
				));
					
				
				return 0;
			});

			// update them into the products collection for the order we just added 
		} catch ( err ) 
		{
			console.log( err );
		}		
	}

	async function deleteOrder( docId: string ) {
		try
		{
			// todo, we should clean up the subcollection here, do this in a cloud function

			//let response = await ordersRef.doc(docId).collection("Products").get();
			//console.log( response );

			await ordersRef.doc(docId).delete();
		} catch( err )
		{
			console.log( err );
		}
	}

	const dateFormat = { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" };

	return (
		<>
			{/* Add an order Modal*/}
			<Modal show={showAddModel} onHide={handleModalCancel}>
				<Modal.Header closeButton>
					<Modal.Title>New Order</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Create a new order for producer <b>{activeProducerName}</b>?
					</p>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => handleModalCancel()}>
						Cancel
					</Button>
					<Button variant="primary" onClick={() => handleAddModalSubmit()}>
						Create
					</Button>
				</Modal.Footer>
			</Modal>

			<Switch>
				<Route path={`${path}/:orderId`}>
					<EditOrder />
				</Route>

				<Route path={`${path}/print/:orderId`}>
					<p>This is the print page</p>
				</Route>

				<Route path={url}>
					<Card>
						<Card.Body>
							<h2 className="text-center mb-4">My Orders</h2>							
			
							<SubHeader />

							{error && <Alert variant="danger">{error}</Alert>}

							{loading && (
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							)}

							{ !loading && (<> 
							<div>Orders for Producer: {activeProducerName}</div>
							<ListGroup>
								{orders &&
									orders.docs
										.sort((a, b) => (a.data().created > b.data().created ? -1 : 1))
										.map((doc) => (
										<ListGroupItem key={doc.id}>
											{"#"}{doc.data().invoiceNumber}<br/>
											{doc.data().created ? doc.data().created.toDate().toLocaleDateString("en-US", dateFormat) : ""}
											<br />
											<Link to={"/orders/" + doc.id}>edit</Link>
											<Button variant="link" onClick={(e) => deleteOrder(doc.id)}>
												delete
											</Button>
										</ListGroupItem>
									))}
							</ListGroup>
							<ListGroupItem>
								<Button variant="link" onClick={(e) => showAddModalForCurrentProducer()}>
									+ New Order
								</Button>
							</ListGroupItem> </>)}
						</Card.Body>
					</Card>
				</Route>
			</Switch>
		</>
	);
}
