import { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { productsRef } from "../firebase";
import { Link } from "react-router-dom";
import { CurrencyFormat } from "../CurrencyFormat";

export default function Products(props) {
	const [error /* , setError */] = useState("");
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState(null);
	const [showConfirmDelete, setShowConfirmDelete] = useState(false);

	useEffect(() => {
		setLoading(true);

		let query = productsRef.where("producerId", "==", props.producerId);
		const unsubscribe = query.onSnapshot((snapshot) => {
			setProducts(snapshot);
			setLoading(false);
		});

		return () => {
			unsubscribe();
		};
	}, [props.producerId]);

	// This machinery sucks, do a javascripty thing here
	const [productToDelete, setProductToDelete] = useState(null);

	const confirmDeleteProduct = (doc) => {
		setProductToDelete(doc);
		setShowConfirmDelete(true);
	};

	const deleteProduct = async (doc) => {
		try {
			productsRef.doc(doc.id).delete();
		} catch (err) {
			console.log(err);
		} finally {
			setShowConfirmDelete(false);
		}
	};

	const handleModalCancel = () => {
		setShowConfirmDelete(false);
	};

	/* {doc
		.data()
		.case_sizes?.sort((a, b) => a - b)
		.map((n) => ({ n }))} */

	return (
		<>
			{/* Confirm delete Modal*/}
			{productToDelete && (
				<Modal show={showConfirmDelete} onHide={handleModalCancel}>
					<Modal.Header closeButton>
						<Modal.Title>Delete Product?</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Are you sure you want to delete product {productToDelete.data().name} ?</p>
					</Modal.Body>

					<Modal.Footer>
						<Button variant="secondary" onClick={() => handleModalCancel()}>
							Cancel
						</Button>
						<Button variant="primary" onClick={() => deleteProduct(productToDelete)}>
							Delete
						</Button>
					</Modal.Footer>
				</Modal>
			)}

			<Card>
				<Card.Body>
					{error && <Alert variant="danger">{error}</Alert>}

					{loading && (
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					)}

					<ListGroup>
						{products &&
							products.docs.map((doc) => (
								<ListGroupItem key={doc.id}>
									<b>{doc.data().name}</b> ( {doc.data().sku_id} ) - {doc.data().size_in_ml}mL @{" "}
									{CurrencyFormat.format(doc.data().cost / 100)}
									<br /> deposit = {CurrencyFormat.format(doc.data().deposit / 100)}
									<br />
									case sizes ={" "}
									{doc
										.data()
										.case_sizes?.sort((a, b) => a - b)
										.toString()}
									<br />
									<Link to={`/producers/editproduct/${props.producerId}/${doc.id}`}>edit</Link>
									<Button variant="link" onClick={(e) => confirmDeleteProduct(doc)}>
										delete
									</Button>
								</ListGroupItem>
							))}
					</ListGroup>

					<ListGroupItem>
						<Link to={`/producers/addproduct/${props.producerId}`}>Add Product</Link>
					</ListGroupItem>
				</Card.Body>
			</Card>
		</>
	);
}
