import { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import AddProducer from "./AddProducer";
import EditProducer from "./EditProducer";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import { ListGroup, ListGroupItem, Modal } from "react-bootstrap";
import { producersRef, FirestoreQuery } from "../firebase";
import { Switch, Route, useRouteMatch, Link } from "react-router-dom";
import "../styles.css";

export default function Producers() {
	const [error /*, setError */] = useState("");
	let [searchQuery, setSearchQuery] = useState("");
	const [filteredData, setFilteredData] = useState([]);

	const { isLoading, data } = FirestoreQuery(producersRef);

	let { path, url } = useRouteMatch();

	const [showModal, setShowModal] = useState(false);

	const onSearchChanged = async (e) => {
		setSearchQuery(e.target.value);

		if (e.target.value === "") {
			setFilteredData([]);
			return;
		}

		setFilteredData(data.docs.filter((doc) => doc.data().name.toLowerCase().includes(e.target.value.toLowerCase())));
	};

	const handleModalClose = () => setShowModal(false);
	const handleModalShow = () => setShowModal(true);

	return (
		<>
			<Switch>
				<Route path={`${path}/add`}>
					<AddProducer />
				</Route>

				<Route path={`${path}/edit/:producerId`}>
					<EditProducer />
				</Route>

				<Route path={`${path}/addproduct/:producerId`}>
					<AddProduct />
				</Route>

				<Route path={`${path}/editproduct/:producerId/:productId`}>
					<EditProduct />
				</Route>

				<Route exact path={url}>
					<Modal show={showModal} onHide={handleModalClose}>
						<Modal.Header closeButton>
							<Modal.Title>Modal heading</Modal.Title>
						</Modal.Header>
						<Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleModalClose}>
								Close
							</Button>
							<Button variant="primary" onClick={handleModalClose}>
								Save Changes
							</Button>
						</Modal.Footer>
					</Modal>

					<Card>
						<Card.Body>
							<h2 className="text-center mb-4">Producers</h2>

							<nav className="navbar navbar-light bg-light">
								<form className="form-inline">
									<input
										className="form-control mr-sm-2"
										defaultValue={searchQuery}
										onChange={onSearchChanged}
										type="search"
										placeholder="Search"
										aria-label="Search"
									></input>
								</form>
							</nav>

							{error && <Alert variant="danger">{error}</Alert>}

							{isLoading && (
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							)}

							<Button onClick={handleModalShow}>Test Modal</Button>

							<ListGroup>
								<ListGroupItem>
									<p>
										<Link to={`${path}/add`}>Add a Producer</Link>
									</p>
								</ListGroupItem>

								{searchQuery === "" &&
									data &&
									data.docs
										.sort((a, b) => (a.data().name > b.data().name ? 1 : -1))
										.map((doc) => (
											<ListGroupItem key={doc.id}>
												<h3>{doc.data().name}</h3>
												<p>
													{doc.data().address}
													<br />
													{doc.data().city}, {doc.data().province}, {doc.data().postal_code}
													<br />
													{doc.data().phone_num}
													<br />
													<a href={`mailto:${doc.data().email}`}>{doc.data().email}</a>
													<br />
													<img src={doc.data().logo_url} alt="producer logo" height="200px" />
												</p>
												<p>
													<Link to={`${path}/edit/${doc.id}`}>Edit</Link>
												</p>
												<Button variant="link" onClick={(e) => producersRef.doc(doc.id).delete()}>
													delete
												</Button>
											</ListGroupItem>
										))}

								{filteredData &&
									filteredData.map((doc) => (
										<ListGroupItem key={doc.id}>
											<h3>{doc.data().name}</h3>
											<p>{doc.data().address}</p>
											<p>
												{doc.data().city}, {doc.data().province}, {doc.data().postal_code}
											</p>
											<p>{doc.data().phone_num}</p>
											<p>{doc.data().email}</p>
											<p>
												<Link to={`${path}/edit/${doc.id}`}>Edit</Link>
											</p>
											<Button variant="link" onClick={(e) => producersRef.doc(doc.id).delete()}>
												delete
											</Button>
										</ListGroupItem>
									))}

								{searchQuery !== "" && filteredData.length === 0 && <p>No Results</p>}
							</ListGroup>
						</Card.Body>
					</Card>
				</Route>
			</Switch>
		</>
	);
}
