import React, { Fragment } from 'react';
import Signup from './Signup';
import Dashboard from './Dashboard';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import UpdateProfile from './UpdateProfile';
import Products from './Products';
import Warehouses from './Warehouses';
import Producers from './Producers';
import Customers from './Customers';
import Orders from './Orders';
import PrintOrder from './PrintOrder';
import Header from './Header';
import Container from 'react-bootstrap/Container';
import { AuthProvider } from '../contexts/AuthContext';
import { UserDataProvider} from '../contexts/UserDataContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';


function App() {
	
	return (
			<Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100px" }}>
				<div className="w-100" style={{ maxWidth: "1024px" }}>					
					<Router>
						<AuthProvider>
							<UserDataProvider>
								<Switch>
									<PrivateRoute path="/printorder/:orderId" component={PrintOrder} />
									<Fragment>
										<Header />
										<Route path="/login" component={Login} />
										<Route path="/signup" component={Signup} />
										<Route path="/forgot-password" component={ForgotPassword} />

										<PrivateRoute exact path="/" component={Dashboard} />
										<PrivateRoute exact path="/profile" component={Dashboard} />                                  
										<PrivateRoute path="/update-profile" component={UpdateProfile} />

										<PrivateRoute path="/products" component={Products} />
										<PrivateRoute path="/producers" component={Producers} />
										<PrivateRoute path="/customers" component={Customers} />
										<PrivateRoute path="/warehouses" component={Warehouses} />
										<PrivateRoute path="/orders" component={Orders} />
									</Fragment>									
								</Switch>
							</UserDataProvider>
						</AuthProvider>
					</Router>
				</div>
			</Container>
	);
}

export default App;
