import React, { useRef, useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { userDataRef } from "../firebase";

export default function UpdateProfile() {
	const emailRef = useRef();
	const passwordRef = useRef();
	const passwordConfirmRef = useRef();
	const addressRef = useRef();
	const phoneRef = useRef();
	const { currentUser, updateEmail, updatePassword, logout } = useAuth();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const [userData /* , setUserData */] = useState(null);

	async function writeAddress(address) {
		userDataRef.doc(currentUser.uid).set({
			...userData,
			address: address,
		});
	}

	async function writeContactPhone(contact_phone) {
		userDataRef.doc(currentUser.uid).set({
			...userData,
			contact_phone: contact_phone,
		});
	}

	useEffect(() => {
		console.log("on use effect");

		//productsRefchild( currentUser.uid ).on('value', (snapshot) => {

		/*  if ( currentUser )
        {
            userDataRef.doc( currentUser.uid ).onSnapshot('value', (snapshot) => {
                let userData = snapshot?.val();
    
                //setAddress( userData.address );

                setUserData( userData );
            });
        } */
		/*  */
	}, [currentUser]);

	async function handleSubmitAddress(e) {
		e.preventDefault();

		if (addressRef.current.value === userData?.address) {
			return;
		}

		setLoading(true);
		setError("");

		try {
			await writeAddress(addressRef.current.value);
			console.log("wrote address");
		} catch (err) {
			setError("Failed to update address", error);
		} finally {
			setLoading(false);
			console.log("success");
		}
	}

	async function handleSubmitPhone(e) {
		e.preventDefault();

		if (phoneRef.current.value === userData?.contact_phone) {
			return;
		}

		setLoading(true);
		setError("");

		try {
			await writeContactPhone(phoneRef.current.value);
			console.log("wrote phone");
		} catch (err) {
			setError("Failed to update phone", error);
		} finally {
			setLoading(false);
			//console.log( "success" );
		}
	}

	async function handleSubmit(e) {
		e.preventDefault();

		if (passwordRef.current.value !== passwordConfirmRef.current.value) {
			return setError("Passwords do not match");
		}

		const promises = [];
		setLoading(true);
		setError("");

		if (emailRef.current.value !== currentUser.email) {
			promises.push(updateEmail(emailRef.current.value));
		}

		if (passwordRef.current.value) {
			promises.push(updatePassword(passwordRef.current.value));
		}

		Promise.all(promises)
			.then(() => {
				// if all success
				history.push("/");
			})
			.catch((error) => {
				setError("Failed to update account", error);
			})
			.finally(() => {
				setLoading(false);
				console.log("success");
			});
	}

	async function handleLogout() {
		try {
			await logout();
			history.push("/login");
		} catch {
			setError("Failed to Log Out");
		}
	}

	return (
		<>
			<Card>
				<Card.Body>
					<p> address: {userData?.address}</p>
					<p> phone: {userData?.contact_phone}</p>
					<h2 className="text-center mb-4">Update Profile</h2>
					{error && <Alert variant="danger">{error}</Alert>}
					<Form onSubmit={handleSubmit}>
						<Form.Group id="email">
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="email"
								ref={emailRef}
								required
								defaultValue={currentUser.email}
							/>
						</Form.Group>
						<Form.Group id="password">
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								ref={passwordRef}
								placeholder="Leave blank to keep the same"
							/>
						</Form.Group>
						<Form.Group id="password-confirm">
							<Form.Label>Password Confirmation</Form.Label>
							<Form.Control
								type="password"
								ref={passwordConfirmRef}
								placeholder="Leave blank to keep the same"
							/>
						</Form.Group>
						<Button
							disabled={loading}
							className="w-100"
							type="submit"
						>
							Update
						</Button>
					</Form>
				</Card.Body>
			</Card>
			<Card>
				<Card.Body>
					<Form onSubmit={handleSubmitAddress}>
						<Form.Group id="address">
							<Form.Label>Address</Form.Label>
							<Form.Control
								ref={addressRef}
								placeholder=""
								defaultValue={userData?.address}
							/>
						</Form.Group>
						<Button
							disabled={loading}
							className="w-100"
							type="submit"
						>
							Update Address
						</Button>
					</Form>
				</Card.Body>
			</Card>
			<Card>
				<Card.Body>
					<Form onSubmit={handleSubmitPhone}>
						<Form.Group id="contact_phone">
							<Form.Label>Phone #</Form.Label>
							<Form.Control
								ref={phoneRef}
								placeholder=""
								defaultValue={userData?.contact_phone}
							/>
						</Form.Group>
						<Button
							disabled={loading}
							className="w-100"
							type="submit"
						>
							Update Phone #
						</Button>
					</Form>
				</Card.Body>
			</Card>

			<div className="w-100 text-center mt-2">
				<Button variant="link" onClick={handleLogout}>
					Log Out
				</Button>
			</div>

			<div className="w-100 text-center mt-2">
				<Link to="/">Cancel</Link>
			</div>
		</>
	);
}
