import React, { useEffect, useState } from "react";
import { ordersRef, customersRef, warehousesRef, producersRef, userDataRef } from "../firebase";
import { useParams } from "react-router-dom";
import { OrderType } from "../OrderType";
import PrintOrderProducts from "./PrintOrderProducts";
import PrintOrderTotals from "./PrintOrderTotals";
import "../printorder.css";

import { Document, Page, Text, View, Image, StyleSheet, PDFViewer } from "@react-pdf/renderer";

const pageStyles = StyleSheet.create({
	page: {
		flexDirection: "column",
		backgroundColor: "#FFF",
		padding: "2cm",
	},
	HeaderSection: {
		flexDirection: "row",
		marginBottom: "10px",
	},
	section: {
		flexDirection: "row",
	},
	imageBox: {
		width: "50%",
	},
	image: {
		width: "140px",
		height: "140px",
		horizontalAlign: "left",
	},
	HeaderLabelBox: {
		width: "40%",
	},
	InvoiceLabel: {
		color: "black",
		fontSize: "18px",
		fontWeight: "bold",
		marginBottom: "2px",
		textTransform: "uppercase",
	},
	HeaderLabel: {
		color: "#777",
		fontSize: "11px",
		textTransform: "uppercase",
		marginBottom: "2px",
	},
	HeaderDataLabel: {
		color: "black",
		fontSize: "12px",
		//textTransform: "uppercase",
		marginBottom: "4px",
	},
	ProducersBox: {
		marginBottom: "10px",
		flexDirection: "row",
	},
	SellerBox: {
		width: "60%",
	},
	ProducerTitleLabel: {
		color: "#000",
		fontSize: "15px",
		fontWeight: "bold",
		marginBottom: "1px",
	},
	ProducerDetails: {
		color: "#000",
		fontSize: "12px",
	},
	signatureSection: {
		flexDirection: "row",
		marginTop: "20px",
	},
	signatureBlock: {
		flexDiration: "column",
		width: "50%",
	},
	signatureLine: {
		width: "120px",
		borderBottomWidth: 1,
		borderColor: "gray",
		borderStyle: "solid",
		marginTop: "15px",
		marginBottom: "3px",
	},
	signatureElement: {
		marginTop: "10px",
		marginBottom: "20px",
	},
	signatureLabel: {
		fontSize: "10px",
		marginBottom: "2px",
	},
	WarehouseBox: {
		width: "50%",
	},
	WarehouseValueHeader: {
		fontSize: "11px",
		marginRight: "4px",
		color: "#555",
		textTransform: "uppercase",
		marginBottom: "5px",
	},
	WarehouseNameLabel: {
		fontSize: "11px",
		fontWeight: "bold",
		horizontalAlign: "right",
	},
	WarehouseValueLabel: {
		fontSize: "11px",
		horizontalAlign: "right",
	},
	ProductsBox: {
		marginTop: "20px",
		marginBottom: "40px",
	},
	ProductsHeaderRow: {
		flexDirection: "row",
		width: "100%",
	},
	ProductsRow: {
		flexDirection: "row",
		width: "100%",
	},
	ProductHeaderLabel: {
		fontSize: "11px",
		color: "#555",
		textTransform: "uppercase",
		flexGrow: "2",
	},
	ProductHeaderLabel_Right: {
		fontSize: "11px",
		color: "#555",
		textTransform: "uppercase",
		flexGrow: "2",
		textAlign: "right",
	},
	ProductsColumn_Description: { width: "45%" },
	ProductsColumn_Count: { width: "15%" },
	ProductsColumn_UnitPrice: { width: "20%" },
	ProductsColumn_LinePrice: { width: "20%" },
	ProductName: { width: "45%", fontSize: "12px" },
	ProductCount: { width: "15%", textAlign: "right", fontSize: "12px" },
	ProductPrice: { width: "20%", textAlign: "right", fontSize: "12px" },
	ProductLinePrice: { width: "20%", textAlign: "right", fontSize: "12px" },
	TotalsBox: {
		flexGrow: "2",
	},
	TotalsBoxRow: {
		marginBottom: "5px",
		paddingBottom: "4px",
		borderBottomWidth: 1,
		borderColor: "gray",
		borderStyle: "solid",
		flexDirection: "row",
		width: "100%",
	},
	TotalsBoxRowFinal: {
		marginTop: "5px",
		marginBottom: "5px",
		paddingTop: "4px",
		paddingBottom: "4px",
		borderTopWidth: 1,
		borderBottomWidth: 1,
		borderColor: "gray",
		borderStyle: "solid",
		flexDirection: "row",
		width: "100%",
	},
	TotalsBoxLabel: {
		fontSize: "11px",
		marginRight: "4px",
		color: "#555",
		textTransform: "uppercase",
		flexGrow: "2",
	},
	TotalsBoxValue: {
		fontSize: "11px",
		horizontalAlign: "right",
	},
});

// Create Document Component

export default function PrintOrder() {
	const { orderId } = useParams();

	const [orderData, setOrderData] = useState(null);

	const [customerData, setCustomerData] = useState(null);
	const [warehouseData, setWarehouseData] = useState(null);
	const [producerData, setProducerData] = useState(null);
	const [preparerData, setPreparerData] = useState(null);

	const [loading, setLoading] = useState(true);

	// fetch order data
	useEffect(() => {
		let fetch = async () => {
			setLoading(true);
			try {
				let orderResponse = await ordersRef.doc(orderId).get();
				setOrderData(orderResponse);

				if (orderResponse.data().customerId) {
					let customerResponse = await customersRef.doc(orderResponse.data().customerId).get();
					setCustomerData(customerResponse);
				} else {
					setCustomerData(null);
				}

				if (orderResponse.data().warehouseId) {
					let warehouseResponse = await warehousesRef.doc(orderResponse.data().warehouseId).get();
					setWarehouseData(warehouseResponse);
				} else {
					setWarehouseData(null);
				}

				let producersResponse = await producersRef.doc(orderResponse.data().producerId).get();
				setProducerData(producersResponse);

				let userDataResponse = await userDataRef.doc(orderResponse.data().preparerId).get();
				setPreparerData(userDataResponse);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		};

		return fetch();
	}, [orderId]);

	// change to loading
	if (loading) return <>loading...</>;

	let formType = OrderType.properties[orderData.data().formType].display;

	const dateFormat = { year: "numeric", month: "numeric", day: "numeric" };

	/*const MyDocumentOriginal = () => (
		<Document>
			<Page size="A4" style={styles2.page}>
				<View style={styles2.section}>
					<Text>Section #1</Text>
				</View>
				<View style={styles2.section}>
					<Text>Section #2</Text>
				</View>
			</Page>
		</Document>
	);*/

	// console.log("logo = ", producerData?.data().logo_url);

	let bDebug = false;

	const MyDocument = () => (
		<Document>
			<Page size="A4" style={pageStyles.page}>
				<View style={pageStyles.HeaderSection} debug={bDebug}>
					<View style={pageStyles.imageBox}>
						<Image style={pageStyles.image} src={producerData?.data().logo_url} />
					</View>

					<View style={pageStyles.HeaderLabelBox}>
						<Text style={pageStyles.InvoiceLabel}>Invoice #: {orderData.data().invoiceNumber}</Text>
						<Text style={pageStyles.HeaderDataLabel}>
							Order Date: {orderData.data().created ? orderData.data().created.toDate().toLocaleDateString("en-US", dateFormat) : ""}
						</Text>
						<Text style={pageStyles.HeaderLabel}>{formType} Agency Order Form</Text>
					</View>
				</View>
				<View className="MiddleRow" style={pageStyles.ProducersBox} debug={bDebug}>
					<View style={pageStyles.SellerBox}>
						{producerData && (
							<View className="producer">
								<Text style={pageStyles.HeaderLabel}>Seller</Text>
								<Text style={pageStyles.ProducerTitleLabel}>{producerData.data().name}</Text>
								<Text style={pageStyles.ProducerDetails}>{producerData.data().address}</Text>
								<Text style={pageStyles.ProducerDetails}>
									{producerData.data().city}, {producerData.data().province}
								</Text>
								<Text style={pageStyles.ProducerDetails}>{producerData.data().postal_code}</Text>
								<Text style={pageStyles.ProducerDetails}>Email: {producerData.data().email} </Text>
								<Text style={pageStyles.ProducerDetails}>Phone: {producerData.data().phone_num} </Text>
								{producerData.data().gst_num && <Text style={pageStyles.ProducerDetails}>GST Number: {producerData.data().gst_num}</Text>}
							</View>
						)}
					</View>
					<View style={pageStyles.SellerBox}>
						{customerData && (
							<View className="customer">
								<Text style={pageStyles.HeaderLabel}>Buyer</Text>
								<Text style={pageStyles.ProducerTitleLabel}>{customerData.data().name}</Text>
								<Text style={pageStyles.ProducerDetails}>{customerData.data().address}</Text>
								<Text style={pageStyles.ProducerDetails}>
									{customerData.data().city}, {customerData.data().province}
								</Text>
								<Text style={pageStyles.ProducerDetails}>{customerData.data().postal_code}</Text>
								<Text style={pageStyles.ProducerDetails}>
									License: #{customerData.data().license_number} - {customerData.data().type}
								</Text>
							</View>
						)}
						{!customerData && <Text>No Customer</Text>}
					</View>
				</View>

				<PrintOrderProducts orderData={orderData} pageStyles={pageStyles} />

				<View style={pageStyles.section}>
					{warehouseData && (
						<View style={pageStyles.WarehouseBox}>
							<Text style={pageStyles.WarehouseValueHeader}>Warehouse</Text>
							<Text style={pageStyles.WarehouseNameLabel}>{warehouseData.data().name}</Text>
							<Text style={pageStyles.WarehouseValueLabel}>123 Main Street Temp Address</Text>
							<Text style={pageStyles.WarehouseValueLabel}>Vancouver, BC</Text>
						</View>
					)}
					<PrintOrderTotals orderData={orderData} pageStyles={pageStyles} />
				</View>

				<View style={pageStyles.signatureSection}>
					<View style={pageStyles.signatureBlock}>
						<View style={pageStyles.signatureElement}>
							<View style={pageStyles.signatureLine} />
							<Text style={pageStyles.signatureLabel}>Checked By</Text>
						</View>

						<View style={pageStyles.signatureElement}>
							<View style={pageStyles.signatureLine} />
							<Text style={pageStyles.signatureLabel}>Signature of Driver</Text>
							<Text style={pageStyles.signatureLabel}>Confirms received in good condition</Text>
						</View>
					</View>

					<View style={pageStyles.signatureBlock}>
						<View style={pageStyles.signatureElement}>
							<View style={pageStyles.signatureLine} />
							<Text style={pageStyles.signatureLabel}>{preparerData.data().name}</Text>
							<Text style={pageStyles.signatureLabel}>Prepared By</Text>
						</View>

						<View style={pageStyles.signatureElement}>
							<View style={pageStyles.signatureLine} />
							<Text style={pageStyles.signatureLabel}>Signature of Receiving Licensee Officer</Text>
							<Text style={pageStyles.signatureLabel}>Confirms quantity and vintage</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);

	return (
		<div className="MainFlow">
			<PDFViewer>
				<MyDocument />
			</PDFViewer>
		</div>
	);
}
