import React, { useRef, useState } from 'react'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function Login() {

    const emailRef = useRef<HTMLInputElement>( null );
    const passwordRef = useRef<HTMLInputElement>( null );
    
    const [ error, setError ] = useState<string>('');
    const [ loading, setLoading ] = useState<boolean>( false );

    const { login } = useAuth();
    const history = useHistory();

    async function handleSubmit( e: React.FormEvent<HTMLFormElement> ) {
        e.preventDefault();

        try {
            setError( '' );
            setLoading( true );
            await login( emailRef?.current?.value, passwordRef?.current?.value );
            history.push( "/" );
        } catch {
            setError( 'Failed to log in' );
        }
        setLoading( false );
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <h2 className="text-center mb-4">Log In</h2>
                    { error && <Alert variant="danger">{error}</Alert> }
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Button disabled={loading} className="w-100" type="submit">Log In</Button>
                    </Form>
                    <div className="w-100 text-center mt-3">
                        <Link to="/forgot-password">Forgot Password</Link>
                    </div>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                Don't have an account? <Link to="/signup">Sign Up</Link>
            </div>
        </>
    )
}
