export const OrderStatus = {
	CREATED: 0,
	PRINTED: 1,
	DELIVERED: 2,
	POSTED: 3,
	PAID: 4,

	NUM_STATUS: 5,

	properties: {
		0: { display: "Created" },
		1: { display: "Printed" },
		2: { display: "Delivered" },
		3: { display: "Posted" },
		4: { display: "Paid" },
		5: { display: "NumStatusTypes" },
	},
};
