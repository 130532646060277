import { useState, useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import firebase from "firebase/app";
import Products from "./Products";
import Warehouses from "./Warehouses";
import AddProduct from "./AddProduct";
import { useAuth } from "../contexts/AuthContext";
import { producersRef, storage } from "../firebase";
import { Switch, Route, useRouteMatch, Link, /* useHistory,  */ useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function EditProducer() {
	const { producerId } = useParams();
	const [producer, setProducer] = useState({});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);

	const { currentUser } = useAuth();

	let { path, url } = useRouteMatch();

	//const history = useHistory();

	const nameRef = useRef("");
	const addressRef = useRef("");
	const cityRef = useRef("");
	const postalCodeRef = useRef("");
	const provinceRef = useRef("BC");
	const phoneNumberRef = useRef("");
	const emailRef = useRef("");
	const gstNumRef = useRef("");

	const [logoURL, setLogoURL] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await producersRef.doc(producerId).get();

				let data = { name: "not found" };

				if (response.exists) {
					data = response.data();
				}
				setProducer(data);

				setLogoURL(data?.logo_url);

				setLoading(false);
			} catch (err) {
				console.error(err);
				setLoading(false);
			}
		};

		fetchData();
	}, [producerId]);

	// logo
	// products

	async function handleSubmit(e) {
		e.preventDefault();

		setSuccess(false);

		const updateProducer = {
			name: nameRef?.current?.value,
			address: addressRef?.current?.value,
			city: cityRef?.current?.value,
			postal_code: postalCodeRef?.current?.value,
			province: provinceRef?.current?.value,
			phone_num: phoneNumberRef?.current?.value,
			email: emailRef?.current?.value,
			gst_num: gstNumRef?.current?.value,

			modified_by: currentUser.uid,
			modified: firebase.firestore.FieldValue.serverTimestamp(),
		};

		try {
			await producersRef.doc(producerId).update(updateProducer);

			setSuccess(true);
		} catch (err) {
			setError(error);
		}
	}

	const [logo, setLogo] = useState(null);

	const handleLogoChange = (e) => {
		if (e.target.files[0]) {
			let file = e.target.files[0];
			if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
				window.alert("File does not support. You must use .png or .jpg ");
				return false;
			}

			setLogo(file);
		}
	};

	// this catches the success, sets a timer and clears it if we navigate away before it's done
	useEffect(() => {
		if (success) {
			const timer = setTimeout(() => setSuccess(false), 2000);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [success]);

	const handleLogoUpload = (e) => {
		var uploadTask = storage.child(`logos/${producerId}/${logo.name}`).put(logo);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				// Observe state change events such as progress, pause, and resume
				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				console.log("Upload is " + progress + "% done");
				switch (snapshot.state) {
					case firebase.storage.TaskState.PAUSED: // or 'paused'
						console.log("Upload is paused");
						break;
					case firebase.storage.TaskState.RUNNING: // or 'running'
						console.log("Upload is running");
						break;
					default:
						break;
				}
			},
			(error) => {
				// Handle unsuccessful uploads
				setError(error);
				console.log(error);
			},
			() => {
				// Handle successful uploads on complete
				// For instance, get the download URL: https://firebasestorage.googleapis.com/...
				uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
					console.log("File available at", downloadURL);

					const updateProducer = {
						logo_url: downloadURL,

						modified_by: currentUser.uid,
						modified: firebase.firestore.FieldValue.serverTimestamp(),
					};

					producersRef
						.doc(producerId)
						.update(updateProducer)
						.then(() => {
							console.log("update logo successful");
							//history.push( "/customers" );
							setSuccess(true);
						})
						.catch((error) => {
							setError(error);
						});

					setLogoURL(downloadURL);
				});
			}
		);
	};

	return (
		<>
			<Switch>
				<Route path={`${path}/addproduct`}>
					<AddProduct />
				</Route>

				<Route exact path={url}>
					<Card>
						<Card.Body>
							<h2>Edit Producer</h2>
							<br></br>
							{error && <Alert variant="danger">{error}</Alert>}

							{success && <Alert variant="success">Producer Updated</Alert>}

							{loading && (
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							)}

							{!loading && (
								<Form onSubmit={handleSubmit}>
									<h2>Contact Information</h2>
									<Form.Group as={Row}>
										<Form.Label column sm={3}>
											Name
										</Form.Label>
										<Col sm={9}>
											<Form.Control type="text" ref={nameRef} required defaultValue={producer.name} />
										</Col>
									</Form.Group>

									<Form.Group as={Row}>
										<Form.Label column sm={3}>
											Address
										</Form.Label>
										<Col sm={9}>
											<Form.Control type="text" ref={addressRef} required defaultValue={producer.address} />
										</Col>
									</Form.Group>

									<Form.Group as={Row}>
										<Form.Label column sm={3}>
											City
										</Form.Label>
										<Col sm={3}>
											<Form.Control type="text" ref={cityRef} required defaultValue={producer.city} />
										</Col>
									</Form.Group>

									<Form.Group as={Row}>
										<Form.Label column sm={3}>
											Province
										</Form.Label>
										<Col sm={2}>
											<Form.Control type="text" ref={provinceRef} required defaultValue={producer.province} />
										</Col>
									</Form.Group>

									<Form.Group as={Row}>
										<Form.Label column sm={3}>
											Postal Code
										</Form.Label>
										<Col sm={2}>
											<Form.Control type="text" ref={postalCodeRef} required defaultValue={producer.postal_code} />
										</Col>
									</Form.Group>

									<Form.Group as={Row}>
										<Form.Label column sm={3}>
											Phone
										</Form.Label>
										<Col sm={3}>
											<Form.Control type="tel" ref={phoneNumberRef} required defaultValue={producer.phone_num} />
										</Col>
									</Form.Group>

									<Form.Group as={Row}>
										<Form.Label column type="email" sm={3}>
											Email
										</Form.Label>
										<Col sm={4}>
											<Form.Control type="tel" ref={emailRef} required defaultValue={producer.email} />
										</Col>
									</Form.Group>

									<Form.Group as={Row}>
										<Form.Label column type="email" sm={3}>
											GST Number
										</Form.Label>
										<Col sm={4}>
											<Form.Control ref={gstNumRef} required defaultValue={producer.gst_num} />
										</Col>
									</Form.Group>

									<Button disabled={false} className="w-50" type="submit">
										Update
									</Button>

									<Form.Group>
										<Form.Label column sm={3}>
											Logo
										</Form.Label>

										<input type="file" onChange={handleLogoChange} />
										<button onClick={handleLogoUpload}>Upload</button>
										<br />

										{logoURL && <img src={logoURL} alt="producer logo"></img>}
									</Form.Group>

									<h2>Products</h2>
									<Products producerId={producerId} />
									<br />

									<h2>Warehouses</h2>
									<Warehouses producerId={producerId} />

									<div>
										<Link to={"/producers"}>Back to Producers</Link>
									</div>
								</Form>
							)}
						</Card.Body>
					</Card>
				</Route>
			</Switch>
		</>
	);
}
