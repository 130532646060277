import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ordersRef, customersRef, warehousesRef, producersRef, userDataRef } from "../firebase";
import { useParams } from "react-router-dom";
import { OrderStatus } from "../OrderStatus";
import { OrderType } from "../OrderType";
import SelectSearch from "react-select-search";
import EditOrderProducts from "./EditOrderProducts";
import { Link } from "react-router-dom";

export default function EditOrder() {
	const { orderId } = useParams();
	const [error, setError] = useState("");

	const orderStatusRef = useRef(null);
	const orderTypeRef = useRef(null);
	const invoiceRef = useRef(null);

	const [customerData, setCustomerData] = useState(null);
	const [warehouseData, setWarehouseData] = useState(null);
	const [producerData, setProducerData] = useState(null);
	const [preparerData, setPreparerData] = useState(null);

	const [orderData, setOrderData] = useState(null);

	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [selectedWarehouse, setSelectedWarehouse] = useState(null);

	const [loading, setLoading] = useState(true);

	// get customer data once at load
	// lordy
	useEffect(() => {
		let fetch = async () => {
			if (orderData === null) return;
			try {
				let customersResponse = await customersRef.get();
				setCustomerData(customersResponse);

				let warehousesResponse = await warehousesRef.where("producerId", "==", orderData.data().producerId).get();
				setWarehouseData(warehousesResponse);

				let producersResponse = await producersRef.doc(orderData.data().producerId).get();
				setProducerData(producersResponse);

				let userDataResponse = await userDataRef.doc(orderData.data().preparerId).get();
				setPreparerData(userDataResponse);
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		};

		return fetch();
	}, [orderData]);

	// fetch order data
	useEffect(() => {
		let unsubscribe = ordersRef.doc(orderId).onSnapshot((snapshot) => {
			let doc = snapshot;
			setOrderData(doc);
			setSelectedCustomer(doc.data().customerId);
			setSelectedWarehouse(doc.data().warehouseId);
		});

		return () => {
			unsubscribe();
		};
	}, [orderId]);

	async function onStatusChanged(e) {
		let val = e.target.value;

		setError("");

		if (val < 0 || val > OrderStatus.NUM_STATUS) return;

		if (orderData.data().status === OrderStatus.CREATED && val >= OrderStatus.CREATED) {
			// customer empty?
			if (!selectedCustomer) {
				setError("Cannot change status: No Customer selected");
				e.target.value = orderData.data().status;
				return;
			}

			// warehouse empty?
			if (!selectedWarehouse) {
				setError("Cannot change status: No Warehouse selected");
				e.target.value = orderData.data().status;
				return;
			}

			// num products is 0?
			let productData = await orderData.ref.collection("products").get();
			const anyProductsHaveCount = productData.docs.some((doc) => doc.data().unit_count > 0);
			if (!anyProductsHaveCount) {
				setError("Cannot change status: No products entered");
				e.target.value = orderData.data().status;
				return;
			}

			// invoice id is undefined?
			if (invoiceRef.current.value === undefined || invoiceRef.current.value === "") {
				setError("Cannot change status: Invoice ID is not valid");
				e.target.value = orderData.data().status;
				return;
			}
		}

		try {
			await ordersRef.doc(orderId).update({ status: Number(val) });
		} catch (err) {
			console.log("error: ", err);
		}
	}

	async function onInvoiceNumberChanged(e) {
		let val = e.target.value;
		try {
			await ordersRef.doc(orderId).update({ invoiceNumber: val });
		} catch (err) {
			console.log("error: ", err);
		}
	}

	async function onFormTypeChanged(e) {
		let val = e.target.value;

		if (val < 0 || val > OrderType.NUM_TYPES) return;

		try {
			await ordersRef.doc(orderId).update({ formType: val });
		} catch (err) {
			console.log("error: ", err);
		}
	}

	async function onSelectedCustomerChanged(e) {
		try {
			setSelectedCustomer(e);
			await ordersRef.doc(orderId).update({ customerId: e });
		} catch (err) {
			console.log("error: ", err);
		}
	}

	async function onSelectedWarehouseChanged(e) {
		try {
			setSelectedWarehouse(e);
			await ordersRef.doc(orderId).update({ warehouseId: e });
		} catch (err) {
			console.log("error: ", err);
		}
	}

	// change to loading
	if (loading) return <>loading...</>;

	var i;

	var orderStatusOptions = [];
	for (i = 0; i < OrderStatus.NUM_STATUS; i++) {
		orderStatusOptions.push(
			<option key={i} value={i}>
				{OrderStatus.properties[i].display}
			</option>
		);
	}

	var orderTypeOptions = [];
	for (i = 0; i < OrderType.NUM_TYPES; i++) {
		orderTypeOptions.push(
			<option key={i} value={i}>
				{OrderType.properties[i].display}
			</option>
		);
	}

	var customerOptions = [];
	if (customerData) {
		customerData.docs
			.sort((a, b) => (a.data().name.toLowerCase() > b.data().name.toLowerCase() ? 1 : -1))
			.map((customer, i) => {
				customerOptions.push({
					name: customer.data().name,
					value: customer.id,
				});

				return 0;
			});
	}

	var warehouseOptions = [];
	if (warehouseData) {
		warehouseData.docs
			.sort((a, b) => (a.data().name.toLowerCase() > b.data().name.toLowerCase() ? 1 : -1))
			.map((warehouse, i) => {
				warehouseOptions.push({
					name: warehouse.data().name,
					value: warehouse.id,
				});

				return 0;
			});
	}

	const dateFormat = { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" };

	let contentLocked = Number(orderData.data().status) !== OrderStatus.CREATED;

	return (
		<>
			<div>
				<Link to={`/printorder/${orderId}`}>View PDF</Link>
			</div>

			<Card>
				<Card.Body>
					<h2 className="text-center mb-4">Edit Order</h2>

					<p>Producer: {producerData.data().name}</p>
					<p>Preparer: {preparerData.data().displayName}</p>

					<Form>
						<p>Date: {orderData.data().created ? orderData.data().created.toDate().toLocaleDateString("en-US", dateFormat) : ""}</p>

						<Form.Group as={Row}>
							<Col sm={5}>
								<Form.Label>Customer</Form.Label>

								<SelectSearch
									disabled={contentLocked}
									options={customerOptions}
									value={selectedCustomer}
									onChange={onSelectedCustomerChanged}
									search
									placeholder="Select a Customer"
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Col sm={5}>
								<Form.Label>Warehouse</Form.Label>

								<SelectSearch
									disabled={contentLocked}
									options={warehouseOptions}
									value={selectedWarehouse}
									onChange={onSelectedWarehouseChanged}
									search
									placeholder="Select a Warehouse"
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Col sm={3}>
								<Form.Label sm={3}>Invoice ID</Form.Label>
								<Form.Control
									disabled={contentLocked}
									className="w-100"
									ref={invoiceRef}
									onBlur={onInvoiceNumberChanged}
									defaultValue={orderData.data().invoiceNumber}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Col sm={3}>
								<Form.Label sm={3}>Status</Form.Label>
								<Form.Control
									sm={3}
									className="w-100"
									as="select"
									onChange={(e) => {
										onStatusChanged(e);
									}}
									defaultValue={orderData.data().status}
									ref={orderStatusRef}
								>
									{orderStatusOptions}
								</Form.Control>
							</Col>
						</Form.Group>

						{error && <Alert variant="danger">{error}</Alert>}

						<Form.Group as={Row}>
							<Col sm={3}>
								<Form.Label sm={3}>Order Type</Form.Label>
								<Form.Control
									disabled={contentLocked}
									sm={4}
									className="w-100"
									as="select"
									onChange={(e) => {
										onFormTypeChanged(e);
									}}
									defaultValue={orderData.data().formType}
									ref={orderTypeRef}
								>
									{orderTypeOptions}
								</Form.Control>
							</Col>
						</Form.Group>
					</Form>

					<EditOrderProducts orderData={orderData} disabled={contentLocked} />
				</Card.Body>
			</Card>
		</>
	);
}
