import { useState, useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import firebase from "firebase/app";
import { useAuth } from "../contexts/AuthContext";
import { productsRef } from "../firebase";
import { Link, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function EditProduct() {
	const { producerId, productId } = useParams();
	const [product, setProduct] = useState({});
	const [loading, setLoading] = useState(false);

	const [cost, setCost] = useState(0);
	const [deposit, setDeposit] = useState(0);

	useEffect(() => {
		let unsubscribe = productsRef.doc(productId).onSnapshot((snapshot) => {
			setProduct(snapshot.data());
			setLoading(false);

			console.log("useEffect, cost is ", snapshot.data().cost);
			setCost(snapshot.data().cost / 100);
			setDeposit(snapshot.data().deposit / 100);
		});

		return () => {
			unsubscribe();
		};
	}, [productId]);

	const nameRef = useRef("");
	const skuRef = useRef("");
	const unitSizeRef = useRef("");

	const addCaseSizeRef = useRef("");

	const { currentUser } = useAuth();

	const [error, setError] = useState("");

	const [success, setSuccess] = useState(false);

	async function handleSubmit(e) {
		e.preventDefault();

		setSuccess(false);

		// do we have a customer with the same name or license number?

		console.log("set cost to ", Math.round(cost * 100));
		console.log("set deposit to ", Math.round(deposit * 100));

		const updateProduct = {
			name: nameRef?.current?.value,
			size_in_ml: unitSizeRef?.current?.value,
			sku_id: skuRef?.current?.value,
			deposit: Math.round(deposit * 100),
			cost: Math.round(cost * 100),
			modified_by: currentUser.uid,
			modified: firebase.firestore.FieldValue.serverTimestamp(),
		};

		try {
			await productsRef.doc(productId).update(updateProduct);
			setSuccess(true);
		} catch (err) {
			setError(err);
			console.log(err);
		}
	}

	// this catches the success, sets a timer and clears it if we navigate away before it's done
	useEffect(() => {
		if (success) {
			const timer = setTimeout(() => setSuccess(false), 2000);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [success]);

	let madeAnyChanges = true;

	async function addCaseSize() {
		let caseSize = Number(addCaseSizeRef?.current?.value);
		console.log("add ", caseSize);

		if (caseSize <= 0 || caseSize > 99) {
			setError("Invalid case size. Must be between 1 and 99");
			return;
		}

		console.log("sizes: ", product.case_sizes);
		console.log("add:", caseSize);

		// is caseSize already in case_sizes?
		if (product.case_sizes.includes(caseSize)) {
			console.log("dup");
			return;
		}

		try {
			await productsRef.doc(productId).update({
				case_sizes: firebase.firestore.FieldValue.arrayUnion(caseSize),
			});
			setError(false);
		} catch (err) {
			console.log(err);
		}
	}

	async function removeCaseSize(caseSize) {
		console.log("Remove ", caseSize);
		try {
			await productsRef.doc(productId).update({
				case_sizes: firebase.firestore.FieldValue.arrayRemove(caseSize),
			});
		} catch (err) {
			console.log(err);
		}
	}

	function onCostChange(values) {
		const { value } = values;
		setCost(value);
	}

	function onDepositChange(values) {
		const { value } = values;
		setDeposit(value);
	}

	return (
		<>
			<Card>
				<Card.Body>
					<h2>Edit Product</h2>
					<br></br>
					{error && <Alert variant="danger">{error}</Alert>}

					{success && <Alert variant="success">Product Updated</Alert>}

					{loading && (
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					)}

					{!loading && (
						<Form onSubmit={handleSubmit}>
							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									Name
								</Form.Label>
								<Col sm={9}>
									<Form.Control type="text" ref={nameRef} required defaultValue={product.name} />
								</Col>
							</Form.Group>
							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									SKU ID
								</Form.Label>
								<Col sm={3}>
									<Form.Control type="text" ref={skuRef} required defaultValue={product.sku_id} />
								</Col>
							</Form.Group>
							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									Unit size in mL
								</Form.Label>
								<Col sm={3}>
									<Form.Control type="number" ref={unitSizeRef} required defaultValue={product.size_in_ml} />
								</Col>
							</Form.Group>
							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									Price per unit
								</Form.Label>
								<Col sm={3}>
									<NumberFormat
										value={cost}
										thousandSeparator={true}
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={"$"}
										allowNegative={false}
										onValueChange={onCostChange}
										type="tel"
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									Deposit per unit
								</Form.Label>
								<Col sm={3}>
									<NumberFormat
										value={deposit}
										thousandSeparator={true}
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={"$"}
										allowNegative={false}
										onValueChange={onDepositChange}
										type="tel"
									/>
								</Col>
							</Form.Group>

							<Form.Group>
								<Form.Label column sm={3}>
									Case Sizes
									{product.case_sizes
										?.sort((a, b) => a - b)
										.map((n) => (
											<div key={n} className="row">
												<Form.Label column sm={3}>
													{n}
												</Form.Label>
												<Col>
													<Button
														disabled={false}
														variant="outline-dark"
														onClick={() => {
															removeCaseSize(n);
														}}
													>
														x
													</Button>
												</Col>
											</div>
										))}
								</Form.Label>

								<div className="row">
									<Col sm={2}>
										<Form.Control type="number" ref={addCaseSizeRef} defaultValue={1} />
									</Col>
									<Button
										disabled={false}
										className="w-50"
										onClick={() => {
											addCaseSize();
										}}
									>
										Add Case Size
									</Button>
								</div>
							</Form.Group>

							<Button disabled={!madeAnyChanges} className="w-50" type="submit">
								Update
							</Button>
							<div>
								<Link to={`/producers/edit/${producerId}`}>Back</Link>
							</div>
						</Form>
					)}
				</Card.Body>
			</Card>
		</>
	);
}
