import { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import AddCustomer from "./AddCustomer";
import EditCustomer from "./EditCustomer";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { customersRef, FirestoreQuery } from "../firebase";
import { Switch, Route, useRouteMatch, Link } from "react-router-dom";
import "../styles.css";

//
import { useUserData } from "../contexts/UserDataContext";

export default function Customers() {
	const [error /*, setError */] = useState("");
	const { isLoading, data } = FirestoreQuery(customersRef);

	const [filteredData, setFilteredData] = useState([]);

	let { path, url } = useRouteMatch();

	const { currentUserData } = useUserData();

	const test = () => {
		console.log(currentUserData);
	};

	let [searchQuery, setSearchQuery] = useState("");

	const onSearchChanged = async (e) => {
		setSearchQuery(e.target.value);

		if (e.target.value === "") {
			setFilteredData([]);
			return;
		}

		setFilteredData(data.docs.filter((doc) => doc.data().name.toLowerCase().includes(e.target.value.toLowerCase())));
	};

	return (
		<>
			<Switch>
				<Route path={`${path}/add`}>
					<AddCustomer />
				</Route>

				<Route path={`${path}/edit/:customerId`}>
					<EditCustomer />
				</Route>

				<Route exact path={url}>
					<Card>
						<Card.Body>
							<h2 className="text-center mb-4">Customers</h2>

							<nav className="navbar navbar-light bg-light">
								<form className="form-inline">
									<input
										className="form-control mr-sm-2"
										defaultValue={searchQuery}
										onChange={onSearchChanged}
										type="search"
										placeholder="Search"
										aria-label="Search"
									></input>
								</form>
							</nav>

							{error && <Alert variant="danger">{error}</Alert>}
							{isLoading && (
								<div className="spinner-border" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							)}

							<ListGroup>
								{searchQuery === "" &&
									data &&
									data.docs.map((doc) => (
										<ListGroupItem key={doc.id}>
											<p>
												{doc.data().name} ( {doc.data().license_number} ){" "}
											</p>
											<p>
												<Link to={`${path}/edit/${doc.id}`}>Edit</Link>
											</p>

											<Button variant="link" onClick={(e) => customersRef.doc(doc.id).delete()}>
												delete
											</Button>
										</ListGroupItem>
									))}

								{filteredData &&
									filteredData.map((doc) => (
										<ListGroupItem key={doc.id}>
											<p>
												{doc.data().name} ( {doc.data().license_number} ){" "}
											</p>
											<p>
												<Link to={`${path}/edit/${doc.id}`}>Edit</Link>
											</p>
											<Button variant="link" onClick={(e) => customersRef.doc(doc.id).delete()}>
												delete
											</Button>
										</ListGroupItem>
									))}

								{searchQuery !== "" && filteredData.length === 0 && <p>No Results</p>}

								<ListGroupItem>
									<p>
										<Link to={`${path}/add`}>Add a Customer</Link>
									</p>
									<button onClick={test}>test</button>
								</ListGroupItem>
							</ListGroup>
						</Card.Body>
					</Card>
				</Route>
			</Switch>
		</>
	);
}
