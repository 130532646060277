import { useState, useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import firebase from "firebase/app";
import { useAuth } from "../contexts/AuthContext";
import { customersRef } from "../firebase";
import { Link, useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function EditCustomer() {
	const { customerId } = useParams();
	const [customer, setCustomer] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await customersRef.doc(customerId).get();

				let data = { name: "not found" };

				if (response.exists) {
					data = response.data();
				}
				setCustomer(data);
				console.log(data);

				setLoading(false);
			} catch (err) {
				console.error(err);
				setLoading(false);
			}
		};

		fetchData();
	}, [customerId]);

	//const { isLoading, data } = FirestoreQuery( ref );

	const nameRef = useRef("");
	const addressRef = useRef("");
	const cityRef = useRef("");
	const postalCodeRef = useRef("");
	const provinceRef = useRef("BC");
	const phoneNumberRef = useRef("");
	const typeRef = useRef("LRS"); // LRS | Licensee | Agency | Counter | VQA | RAS | MOS
	const licenseNumberRef = useRef(0);

	const { currentUser } = useAuth();

	const [error, setError] = useState("");

	const [success, setSuccess] = useState(false);

	async function handleSubmit(e) {
		e.preventDefault();

		setSuccess(false);

		// do we have a customer with the same name or license number?

		const updateCustomer = {
			name: nameRef?.current?.value,
			address: addressRef?.current?.value,
			city: cityRef?.current?.value,
			postal_code: postalCodeRef?.current?.value,
			province: provinceRef?.current?.value,
			phone_num: phoneNumberRef?.current?.value,
			type: typeRef?.current?.value,
			license_number: licenseNumberRef?.current?.value,
			modified_by: currentUser.uid,
			modified: firebase.firestore.FieldValue.serverTimestamp(),
		};

		try {
			await customersRef.doc(customerId).update(updateCustomer);
			setSuccess(true);
		} catch (err) {
			setError(error);
		}
	}

	// this catches the success, sets a timer and clears it if we navigate away before it's done
	useEffect(() => {
		if (success) {
			const timer = setTimeout(() => setSuccess(false), 2000);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [success]);

	let madeAnyChanges = true;

	return (
		<>
			<Card>
				<Card.Body>
					<h2>Edit Customer</h2>
					<br></br>
					{error && <Alert variant="danger">{error}</Alert>}

					{success && <Alert variant="success">Customer Updated</Alert>}

					{loading && (
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					)}

					{!loading && (
						<Form onSubmit={handleSubmit}>
							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									Name
								</Form.Label>
								<Col sm={9}>
									<Form.Control type="text" ref={nameRef} required defaultValue={customer.name} />
								</Col>
							</Form.Group>

							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									Address
								</Form.Label>
								<Col sm={9}>
									<Form.Control type="text" ref={addressRef} required defaultValue={customer.address} />
								</Col>
							</Form.Group>

							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									City
								</Form.Label>
								<Col sm={3}>
									<Form.Control type="text" ref={cityRef} required defaultValue={customer.city} />
								</Col>
							</Form.Group>

							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									Province
								</Form.Label>
								<Col sm={2}>
									<Form.Control type="text" ref={provinceRef} required defaultValue={customer.province} />
								</Col>
							</Form.Group>

							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									Postal Code
								</Form.Label>
								<Col sm={2}>
									<Form.Control type="text" ref={postalCodeRef} required defaultValue={customer.postal_code} />
								</Col>
							</Form.Group>

							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									Phone
								</Form.Label>
								<Col sm={3}>
									<Form.Control type="tel" ref={phoneNumberRef} required defaultValue={customer.phone_num} />
								</Col>
							</Form.Group>

							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									Type
								</Form.Label>
								<Col sm={9}>
									<Form.Control className="w-50" as="select" ref={typeRef} defaultValue={customer.type}>
										<option>LRS</option>
										<option>RAS</option>
										<option>Licensee</option>
										<option>Agency</option>
										<option>Counter</option>
										<option>VQA</option>
										<option>MOS</option>
										<option>Hospitality</option>
									</Form.Control>
								</Col>
							</Form.Group>

							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									BCLDB Number
								</Form.Label>
								<Col sm={2}>
									<Form.Control type="number" ref={licenseNumberRef} required defaultValue={customer.license_number} />
								</Col>
							</Form.Group>

							<Button disabled={!madeAnyChanges} className="w-50" type="submit">
								Update
							</Button>

							<div>
								<Link to={"/customers"}>Back to Customers</Link>
							</div>
						</Form>
					)}
				</Card.Body>
			</Card>
		</>
	);
}
