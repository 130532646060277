import { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import firebase from "firebase/app";
import { useAuth } from "../contexts/AuthContext";
import { producersRef, storage } from "../firebase";
import { Link, useHistory } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function AddProducer() {
	const nameRef = useRef("");
	const addressRef = useRef("");
	const cityRef = useRef("");
	const postalCodeRef = useRef("");
	const provinceRef = useRef("BC");
	const phoneNumberRef = useRef("");
	const emailRef = useRef("");
	const [logo, setLogo] = useState(null);

	const { currentUser } = useAuth();

	const [error, setError] = useState("");

	const [validLogoSize, setValidLogoSize] = useState(false);

	const history = useHistory();

	async function uploadFile(producerId, logo) {
		return new Promise(function (resolve, reject) {
			var uploadTask = storage.child(`logos/${producerId}/${logo.name}`).put(logo);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					// Observe state change events such as progress, pause, and resume
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log("Upload is " + progress + "% done");
					switch (snapshot.state) {
						case firebase.storage.TaskState.PAUSED: // or 'paused'
							console.log("Upload is paused");
							break;
						case firebase.storage.TaskState.RUNNING: // or 'running'
							console.log("Upload is running");
							break;
						default:
							break;
					}
				},
				(error) => {
					// Handle unsuccessful uploads
					reject(error);
				},
				() => {
					// Handle successful uploads on complete
					// For instance, get the download URL: https://firebasestorage.googleapis.com/...
					resolve(uploadTask.snapshot.ref);
				}
			);
		});
	}

	async function handleSubmit(e) {
		e.preventDefault();

		const newProducer = {
			name: nameRef?.current?.value,
			address: addressRef?.current?.value,
			city: cityRef?.current?.value,
			postal_code: postalCodeRef?.current?.value,
			province: provinceRef?.current?.value,
			phone_num: phoneNumberRef?.current?.value,
			email: emailRef?.current?.value,

			logo_url: "",

			created_by: currentUser.uid,
			created: firebase.firestore.FieldValue.serverTimestamp(),
			modified_by: currentUser.uid,
			modified: firebase.firestore.FieldValue.serverTimestamp(),
		};

		try {
			// create the new producer
			const response = await producersRef.add(newProducer);

			const producerId = response.id;

			// upload the logo
			const ref = await uploadFile(producerId, logo);
			console.log(ref);

			// fetch the download URL
			const downloadURL = await ref.getDownloadURL();
			console.log("File available at", downloadURL);

			// Update the Producer with the new logo URL
			const updateProducer = {
				logo_url: downloadURL,
				modified_by: currentUser.uid,
				modified: firebase.firestore.FieldValue.serverTimestamp(),
			};

			await producersRef.doc(producerId).update(updateProducer);
		} catch (error) {
			console.log(error);
			setError("Error Adding new Producer");
		} finally {
			history.push("/producers");
		}
	}

	const handleLogoChange = (e) => {
		if (e.target.files[0]) {
			let file = e.target.files[0];
			if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
				window.alert("File does not support. You must use .png or .jpg ");
				return false;
			}

			setError(false);

			var reader = new FileReader();

			//Read the contents of Image File.
			reader.readAsDataURL(file);
			reader.onload = function (e) {
				//Initiate the JavaScript Image object.
				var image = new Image();

				//Set the Base64 string return from FileReader as source.
				image.src = e.target.result;

				//Validate the File Height and Width.
				image.onload = function () {
					var height = this.height;
					var width = this.width;
					if (height > 400 || width > 400) {
						setError("Logo Height and Width must not exceed 400px.");
						setValidLogoSize(false);
						return false;
					}
					setValidLogoSize(true);
					return true;
				};
			};

			setError(false);
			console.log(file);

			setLogo(file);
		}
	};

	return (
		<>
			<Card>
				<Card.Body>
					<h2>Add a new Producer</h2>
					<br />

					<Form onSubmit={handleSubmit}>
						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Name
							</Form.Label>
							<Col sm={9}>
								<Form.Control type="text" ref={nameRef} required placeholder="" />
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Address
							</Form.Label>
							<Col sm={9}>
								<Form.Control type="text" ref={addressRef} required placeholder="" />
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								City
							</Form.Label>
							<Col sm={3}>
								<Form.Control type="text" ref={cityRef} required placeholder="" />
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Province
							</Form.Label>
							<Col sm={2}>
								<Form.Control type="text" ref={provinceRef} required placeholder="" />
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Postal Code
							</Form.Label>
							<Col sm={2}>
								<Form.Control type="text" ref={postalCodeRef} required placeholder="" />
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Phone
							</Form.Label>
							<Col sm={3}>
								<Form.Control type="tel" ref={phoneNumberRef} required placeholder="(604)111-1111" />
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column type="email" sm={3}>
								Email
							</Form.Label>
							<Col sm={4}>
								<Form.Control type="tel" ref={emailRef} required defaultValue="" />
							</Col>
						</Form.Group>

						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Logo
							</Form.Label>
							<input type="file" onChange={handleLogoChange} />
						</Form.Group>

						{error && <Alert variant="danger">{error}</Alert>}

						<Button disabled={!validLogoSize} className="w-50" type="submit">
							Submit
						</Button>

						<div>
							<Link to={"/producers"}>Cancel</Link>
						</div>
					</Form>
				</Card.Body>
			</Card>
		</>
	);
}
