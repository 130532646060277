import React, { useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { userDataRef } from "../firebase";

const UserDataContext = React.createContext();

export function useUserData() {
	return useContext(UserDataContext);
}

export function UserDataProvider({ children }) {
	const [currentUserData, setCurrentUserData] = useState(null);

	const { currentUser } = useAuth();

	useEffect(() => {
		if (!currentUser) return;

		let unsubscribe;

		async function getUserData() {
			if (!currentUser) {
				unsubscribe = null;
				return;
			}
			try {
				// if the userdata doesn't exist, create it
				const userDataDocRef = await userDataRef.doc(currentUser.uid);
				const doc = await userDataDocRef.get();
				if (!doc.exists) {
					await userDataRef.doc(currentUser.uid).set({});
				}
			} catch (err) {
				console.log(err);
			}

			unsubscribe = userDataRef.doc(currentUser.uid).onSnapshot((docs) => {
				setCurrentUserData(docs.data());
			});
		}

		getUserData(currentUser);
		return unsubscribe;
	}, [currentUser]);

	async function updateUserData(data) {
		try {
			userDataRef.doc(currentUser.uid).update(data);
		} catch (err) {
			console.log("updateUserData error: ", err);
		}
	}

	const value = {
		//userData,
		currentUserData,
		updateUserData,
	};

	return <UserDataContext.Provider value={value}>{children}</UserDataContext.Provider>;
}
