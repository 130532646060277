import React, { useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useUserData } from "../contexts/UserDataContext";
import { producersRef, FirestoreQuery } from "../firebase";

export default function Dashboard() {
	const [error, setError] = useState("");

	const { currentUser, logout } = useAuth();
	const { currentUserData, updateUserData } = useUserData();

	const { isLoading, data } = FirestoreQuery(producersRef);

	const history = useHistory();

	const activeProducerRef = useRef("");

	async function handleLogout() {
		try {
			await logout();
			history.push("/login");
		} catch {
			setError("Failed to Log Out");
		}
	}

	/* <p>Address: { currentUserData?.address}</p>  */

	async function onProducerChanged(e) {
		e.preventDefault();
		try {
			updateUserData({ active_producer: e.target.value });
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<>
			<Card>
				<Card.Body>
					<h2 className="text-center mb-4">Profile</h2>
					{error && <Alert variant="danger">{error}</Alert>}
					<p>Email: {currentUser.email}</p>
					<p>Address: {currentUserData?.address}</p>
					<p>Phone: {currentUserData?.contact_phone}</p>

					<Form>
						<Form.Group>
							<Form.Label column sm={6}>
								Active Producer{" "}
								{currentUserData?.active_producer}
							</Form.Label>
							<Form.Control
								className="w-50"
								as="select"
								value={currentUserData?.active_producer}
								ref={activeProducerRef}
								onChange={onProducerChanged}
							>
								{!isLoading &&
									data &&
									data.docs.map((doc) => (
										<option key={doc.id} value={doc.id}>
											{doc.data().name}
										</option>
									))}
							</Form.Control>
						</Form.Group>
					</Form>

					<Link
						to="/update-profile"
						className="btn btn-primary w-100 mt-3"
					>
						Update Profile
					</Link>
				</Card.Body>
			</Card>

			<div className="w-100 text-center mt-2">
				<Button variant="link" onClick={handleLogout}>
					Log Out
				</Button>
			</div>
		</>
	);
}
