import { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NumberFormat from "react-number-format";
import "../styles.css";

export default function OrderProduct(props) {
	//props.doc

	// do our own updates
	// don't need to tell EditOrderProducts, useEffect will catch the change to props.orderData

	const unitCountRef = useRef(null);
	const [cost, setCost] = useState(0);

	useEffect(() => {
		setCost(props.doc.data().cost / 100);
	}, [props]);

	function onUnitCountChange() {
		let num = Number(unitCountRef.current.value);
		if (num < 0) num = 0;

		props.SetUnitCount(props.doc.id, num);
	}

	//console.log("scary Render", props.doc.data().case_size);

	function onCostChange(values) {
		const { value } = values;
		setCost(value);

		// actually update the cost
		props.SetCost(props.doc.id, value * 100);
	}

	if (props.disabled && props.doc.data().unit_count === 0) {
		return null;
	}

	return (
		<Row className="h-100 OrderProductRow">
			<Form.Label column sm={5}>
				{props.doc.data().name} {"(" + props.doc.data().sku_id + ")"}
			</Form.Label>
			<Form.Label column sm={2}>
				{props.doc.data().case_size + " pack"}
			</Form.Label>
			<Col sm={2}>
				<NumberFormat
					disabled={props.disabled}
					className="form-control"
					value={cost}
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
					prefix={"$"}
					allowNegative={false}
					onValueChange={onCostChange}
					type="tel"
				/>
			</Col>
			<Col sm={2}>
				<Form.Control
					type="number"
					ref={unitCountRef}
					disabled={props.disabled}
					onChange={onUnitCountChange}
					value={Number(props.doc.data().unit_count).toString()}
				/>
			</Col>
		</Row>
	);
}
