import { useState, useEffect } from "react";
import { CurrencyFormat } from "../CurrencyFormat";

import { Text, View } from "@react-pdf/renderer";

export default function PrintOrderProducts(props) {
	const [products, setProducts] = useState(null);

	useEffect(() => {
		if (props.orderData === null) return;

		let unsubscribe = props.orderData.ref.collection("products").onSnapshot((snapshot) => {
			setProducts(snapshot);
		});

		return () => {
			unsubscribe();
		};
	}, [props.orderData]);

	function SortByNameAndCaseSize(a, b) {
		if (a.data().name !== b.data().name) return a.data().name < b.data().name ? 1 : -1;
		return a.data().case_size - b.data().case_size;
	}

	return (
		<View style={props.pageStyles.ProductsBox}>
			<View style={props.pageStyles.ProductsHeaderRow}>
				<View style={props.pageStyles.ProductsColumn_Description}>
					<Text style={props.pageStyles.ProductHeaderLabel}>Product</Text>
				</View>
				<View style={props.pageStyles.ProductsColumn_Count}>
					<Text style={props.pageStyles.ProductHeaderLabel_Right}>Count</Text>
				</View>
				<View style={props.pageStyles.ProductsColumn_UnitPrice}>
					<Text style={props.pageStyles.ProductHeaderLabel_Right}>Unit Price</Text>
				</View>
				<View style={props.pageStyles.ProductsColumn_LinePrice}>
					<Text style={props.pageStyles.ProductHeaderLabel_Right}>Line Price</Text>
				</View>
			</View>
			{products &&
				products.docs.sort(SortByNameAndCaseSize).map((doc) => (
					<View key={doc.id} style={props.pageStyles.ProductsRow}>
						<Text style={props.pageStyles.ProductName}>
							{doc.data().name} {doc.data().case_size + " pack"}
						</Text>
						<Text style={props.pageStyles.ProductCount}>{doc.data().unit_count}</Text>
						<Text style={props.pageStyles.ProductPrice}>{CurrencyFormat.format((doc.data().cost * doc.data().case_size) / 100)}</Text>
						<Text style={props.pageStyles.ProductLinePrice}>
							{CurrencyFormat.format((doc.data().unit_count * doc.data().case_size * doc.data().cost) / 100)}
						</Text>
					</View>
				))}
		</View>
	);
}

/*
    Description                 Count       Unit Price      Line Price
    Pear Dry 4 Pack #SKUNUM         2            $4.99           $9.98






*/

/*
<ListGroup>
				{products &&
					products.docs
						.sort(SortByNameAndCaseSize)
						.map((doc) => <OrderProduct disabled={true} pdf={true} key={doc.id} doc={doc} SetUnitCount={() => {}} SetCost={() => {}} />)}
			</ListGroup>
            */
